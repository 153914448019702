<template>
  <div class="info">
    <top-bar :title="'修改信息'" :left="true"/>

    <van-popup v-model="sexShow" round position="bottom">
      <van-picker  show-toolbar  :columns="sexList"  @cancel="sexShow = false"  @confirm="sexChange"/>
    </van-popup>
    <van-popup v-model="registryTypeShow" position="bottom">
      <van-picker show-toolbar value-key="label" :columns="registryTypeList" @confirm="registryType"
                  @cancel="registryTypeShow = false" />
    </van-popup>
    <van-calendar
    v-model="dateShow"
    color="#1989fa"
    :show-title="false"
    :show-confirm="false"
    @select="birthdayChange"
    :default-date="new Date(...birthday)"
    :min-date="new Date('1900','1','1')"
    :max-date="new Date()"/>

<!--    <div class="headbox">-->
<!--      <div class="Avatar">-->
<!--        <img :src="myDetailInfo.headImg" alt="">-->
<!--        <div class="headImgbtn">-->
<!--          <van-uploader :multiple="false" :after-read="uploadRead" >-->
<!--          <van-button plain size="small">上传头像</van-button>-->
<!--        </van-uploader>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
    <div class="">
      <div class="title" >
        <div>扫描身份证件可自动生成个人信息</div>
        <van-uploader :after-read="cardIdIdentified">
          <img :src="require('@/assets/img/scan.png')" alt="" class="scan">
        </van-uploader>
      </div>
      <van-cell-group>
        <van-cell title="上传照片" is-link value="" :class="isOld == 1 ? 'oldSize' : ''">
          <template #right-icon>
            <van-uploader :multiple="false" :after-read="uploadRead">
              <div class="headbox">
                <div class="Avatar">
                  <img :src="myDetailInfo.headImg" alt="">
                </div>
              </div>
              <van-icon name="arrow" style="line-height: 1.6rem;"/>
            </van-uploader>
          </template>
        </van-cell>
        <van-field  v-model="myDetailInfo.idNumber"  label="有效证件号"  placeholder="无"  input-align="right" :readonly="idNumberCheck" :error="!idNumberCheck" :class="isOld == 1 ? 'oldSize' : 'widthSet'" @input="idNumberChange"/>
        <van-field  v-model="myDetailInfo.name"  label="姓名"  placeholder="无"  input-align="right" :readonly="nameCheck" :class="isOld == 1 ? 'oldSize' : ''" @input="nameChange"/>
        <van-cell title="性别" :value="myDetailInfo.sex == 1?'男':'女'" is-link @click="sexShow = true" :class="isOld == 1 ? 'oldSize' : ''"/>
        <van-field  v-model="myDetailInfo.mobile"  label="手机号码"  placeholder="无"  input-align="right" :readonly="mobileCheck" :error="!mobileCheck" :class="isOld == 1 ? 'oldSize' : ''" @input="mobileChange"/>
        <van-cell title="民族" :value="myDetailInfo.nationStr || '未知'" is-link  @click="goNation" :class="isOld == 1 ? 'oldSize' : ''"/>
        <van-cell title="国籍" :value="myDetailInfo.nationalityStr || '未知'" is-link @click="goNationality" :class="isOld == 1 ? 'oldSize' : ''"/>
        <van-cell title="出生日期" :value="myDetailInfo.birthday || '未知'" :is-link="!birthdayCheck" @click="!birthdayCheck ? (dateShow = true) : (dateShow = false)" :readonly="birthdayCheck" :value-class="!birthdayCheck ? 'redTip' : 'blackTip'" :class="isOld == 1 ? 'oldSize' : ''"/>
        <van-cell style="background-color: #F5F5F5;padding: 0.15rem 0.4rem;" :border="false"/>
        <van-field  type="textarea" rows="1" :autosize="{minHeight: 26}" v-model="myDetailInfo.company"  label="工作单位"  placeholder="无"  input-align="left" clearable :class="isOld == 1 ? 'oldSize' : ''"/>
        <van-cell title="户籍性质" :value="myDetailInfo.registryTypeStr || '未知'" :is-link="!registryCheck" @click="!registryCheck ? (registryTypeShow = true) : (registryTypeShow = false)" :readonly="registryCheck" :value-class="!registryCheck ? 'redTip' : 'blackTip'" :class="isOld == 1 ? 'oldSize' : ''"/>
        <van-field  type="textarea" rows="1" :autosize="{minHeight: 26}" v-model="myDetailInfo.regAddress"  label="户籍地址"  placeholder="无"  input-align="left" clearable :class="isOld == 1 ? 'oldSize' : ''"/>
        <van-field  v-model="myDetailInfo.emContact"  label="紧急联系人"  placeholder="无"  input-align="right" clearable :class="isOld == 1 ? 'oldSize' : 'widthSet'"/>
        <van-field  v-model="myDetailInfo.emMobile"  label="联系人号码"  placeholder="无"  input-align="right" clearable :class="isOld == 1 ? 'oldSize' : 'widthSet'"/>
        <van-field  type="textarea" rows="1" :autosize="{minHeight: 26}" v-model="myDetailInfo.nowAddress"  label="现居住地"  placeholder="无"  input-align="right" clearable :class="isOld == 1 ? 'oldSize' : ''"/>
        <!-- <van-field  v-model="myDetailInfo.buildingRoom"  label="隔间房号"  placeholder="未知"  input-align="right"/> -->
      </van-cell-group>
      <van-row class="updateBtn">
        <van-col span="12" style="padding-right: 0.2rem;">
          <van-button type="info" round block plain @click="onCancel" :style="{fontSize: isOld == 1 ? '18px' : '16px'}">取消</van-button>
        </van-col>
        <van-col span="12">
          <van-button type="info" round block @click="onSubmit" :style="{fontSize: isOld == 1 ? '18px' : '16px'}">完成</van-button>
        </van-col>
      </van-row>
    </div>
  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import TopBar from '../../components/topBar/topBar.vue'
import { mapState } from 'vuex'
import {getImageStream} from '@/utils/index'
import {formatterDate} from '@/utils/utils'
import {isNULL, IdentityCodeValid, isMobile} from '@/utils/validate'
export default {
  components: {
    topBar
  },
  computed:{...mapState(['nationality','nation'])},
  data () {
    return {
      headImg: '',
      birthday:'',
      myDetailInfo: {},
      sexShow: false,
      dateShow: false,
      registryTypeShow: false,
      sexList:[{text:'男', value:'1'},{text:'女',value:'2'}],
      registryTypeList: [],
      nameCheck: false,
      idNumberCheck: false,
      mobileCheck: false,
      birthdayCheck: false,
      registryCheck: false,
      isOld: null
    }
  },
  methods: {
    cardIdIdentified(e) {
      let formdata = new FormData();
      formdata.append("file", e.file)
      this.$http({
        url: this.$http.adornUrl('/wxapp/file/cardIdIdentified'),
        method: 'post',
        header: {
          wxAppId: 'wxfa4ce77dc91e7327'
        },
        data: formdata
      }).then(({ data }) => {
        if (data.code == 0) {
          this.myDetailInfo.name = data.message.userName
          this.myDetailInfo.idNumberType = 1
          this.myDetailInfo.idNumber = data.message.id
          this.myDetailInfo.regAddress = data.message.addr
          this.myDetailInfo.birthday = data.message.birthday
          this.myDetailInfo.sex = data.message.gender == "男" ? 1 : 2
          this.myDetailInfo.nation = data.message.nation
          this.myDetailInfo.nationStr = data.message.nationality
          this.myDetailInfo.idNumberTypeStr = '身份证'
        } else {
          this.$toast.fail(data.msg)
        }
      })
    },
    getDetailInfo () {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/my/jmggDetailInfo'),
        method: 'post',
        params: this.$http.adornParams({
          orgId: this.$orgId,
          userId: this.$userId
        },false)
      }).then(({data})=> {
        if (data.code == 0) {
          this.myDetailInfo = data.userInfoEntity
          let defaultImg = getImageStream('files/wx/images/content/header-default.png')
          this.myDetailInfo.headImg = data.userInfoEntity.headImg ? getImageStream(data.userInfoEntity.headImg) : defaultImg
          this.isOld = data.userInfoEntity.extended ? JSON.parse(data.userInfoEntity.extended).isOld : null
          if(this.nationality != 0 && typeof this.nationality == 'object') {
            this.myDetailInfo.nationalityStr = this.nationality.label
            this.myDetailInfo['nationality'] = this.nationality.value
          }
          if(this.nation != 0 && typeof this.nation == 'object') {
            this.myDetailInfo.nationStr = this.nation.label
            this.myDetailInfo['nation'] = this.nation.value
          }
          this.birthday = this.myDetailInfo.birthday.split('-')
          if (!isNULL(this.myDetailInfo.name)) {
            this.nameCheck = true
          }
          if (IdentityCodeValid(this.myDetailInfo.idNumber) && !isNULL(this.myDetailInfo.idNumber)) {
            this.idNumberCheck = true
          }
          if (isMobile(this.myDetailInfo.mobile) && !isNULL(this.myDetailInfo.mobile)) {
            this.mobileCheck = true
          }
          if (!isNULL(this.myDetailInfo.birthday)) {
            this.birthdayCheck = true
          }
          if (!isNULL(this.myDetailInfo.registryType)) {
            this.registryCheck = true
          }
        } else {
        this.$toast.fail(data.msg);
        }
      })
    },
    getRegistry(){
      //户籍性质
      this.$http({
        url: this.$http.adornUrl(`/wxapp/sys/dict/virtual/registryType`),
        method: 'get',
        params: this.$http.adornParams({})
      }).then(({ data }) => {
        if (data.code == 0) {
          data.registryTypes.map((type) => {
            type.value = type.value + ""
          })
          this.registryTypeList = data.registryTypes
        }
      })
    },
    onSubmit () {
      this.$dialog.confirm({
        message: '确认提交？',
      })
      .then(() => {
      this.$http({
        url: this.$http.adornUrl('/wxapp/user/jmgg/save'),
        method: 'post',
        data: this.$http.adornData({
          birthday: this.myDetailInfo.birthday,
          company: this.myDetailInfo.company,
          emContact: this.myDetailInfo.emContact,
          emMobile: this.myDetailInfo.emMobile,
          headImg: this.headImg,
          id: this.myDetailInfo.id,
          idNumber: this.myDetailInfo.idNumber,
          mobile: this.myDetailInfo.mobile,
          name: this.myDetailInfo.name,
          nation: this.myDetailInfo.nation,
          nationality: this.myDetailInfo.nationality,
          nowAddress: this.myDetailInfo.nowAddress,
          orgId: this.$orgId,
          regAddress: this.myDetailInfo.regAddress,
          sex: this.myDetailInfo.sex,
          registryType: this.myDetailInfo.registryType,
          temporaryHeadImg: '',
          temporaryHeadImgSuffix: ''
        })
      }).then(({data})=> {
        if (data.code == 0) {
          this.$toast.success('修改成功')
          this.$router.push('/my-info')
        } else {
        this.$toast.fail(data.msg);
        }
      })
      })
      .catch(() => {
      });
    },
    onCancel () {
      this.$router.go(-1)
    },
    birthdayChange (value) {
      this.myDetailInfo.birthday = formatterDate(value)
      if (value) {
        this.birthdayCheck = true
      }
    },
    sexChange (value) {
      this.myDetailInfo.sex =  value.value
      this.sexShow = false
    },
    registryType(e) {
      this.myDetailInfo.registryType = parseInt(e.value)
      this.myDetailInfo.registryTypeStr = e.label
      if (e.value) {
        this.registryCheck = true
      }
      this.registryTypeShow = false
    },
    uploadRead (file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration:0,
        overlay: true
      });
      let formdata = new FormData();
      formdata.append("file", file.file);
      formdata.append("path", 'files/userHeadImg');
      this.$http({
        url: this.$http.adornUrl('/wxapp/file/upload'),
        method: 'post',
        data: formdata
      }).then(({data})=> {
          this.$toast.clear()
          if (data.code == 0) {
            this.headImg = data.fileMessage.relativePath
            this.myDetailInfo.headImg = getImageStream(data.fileMessage.relativePath)
          return true
          } else {
          this.$toast.fail(data.msg);
          }
        })
    },
    goNation () {
      this.$router.push('/nation')
    },
    goNationality () {
      this.$router.push('/nationality')
    },
    mobileChange(value){
      if (isMobile(value) && !isNULL(value)) {
        this.mobileCheck = true
      }
    },
    idNumberChange(value){
      if (IdentityCodeValid(value) && !isNULL(value)) {
        this.idNumberCheck = true
      }
    },
    nameChange(value) {
      if (value) {
        this.nameCheck = true
      }
    }
  },
  created () {
    this.getRegistry()
    this.getDetailInfo()
  }
}

    TopBar</script>
<style lang="scss" scoped>
.title {
  line-height: 96px;
  color: #333;
  background-color: white;
  padding: 0 30px;
  font-size: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
}
.scan {
  width: 40px;
  height: 40px;
}
.oldSize {
  ::v-deep .van-cell__title{
    font-size: 40px !important;
  }
  ::v-deep .van-cell__value {
    font-size: 40px !important;
  }
  ::v-deep .van-field__control {
    font-size: 40px !important;
  }
}
.info {
  padding: 20px 0 40px;
  background-color: #F5F5F5;
  .van-cell {
    padding: 26px 30px;
    &:first-child .van-cell__title{
      line-height: 120px;
      flex: 1 !important;
    }
    &:first-child .van-cell__value{
      flex: 1 !important;
    }
    &:nth-child(3) {
      ::v-deep .van-field__label {
        width: 100px !important;
      }
    }
    ::v-deep .van-cell__title{
      font-size: 34px;
      color: #666666;
      font-family: PingFangSC-Regular, PingFang SC;
      //width: auto;
      margin-right: 80px;
      flex: unset !important;
    }
    ::v-deep .van-cell__value {
      font-size: 34px;
      //color: #333333;
      text-align: unset;
      overflow: unset;
      flex: unset !important;
      -webkit-box-flex: unset !important;
      -webkit-flex: none !important;
      //padding-left: 20px;
    }
    ::v-deep .van-field__control {
      font-size: 34px;
      //color: #333333;
      text-align: left;
    }
    ::v-deep .van-field__body {
      width: 6rem;
    }
    ::v-deep .van-field__label {
      //width: unset;
      margin-right: 30px;
    }
    ::v-deep .van-cell__right-icon {
      flex: 1;
      text-align: right;
    }
    ::v-deep .van-uploader__input-wrapper {
      display: flex;
    }
  }
}
.widthSet {
  ::v-deep .van-field__label {
    width: 5.3em !important;
  }
  ::v-deep .van-field__control {
    //width: unset !important;
  }
}
  .headbox {
    background-color: #fff;
    padding-right: 20px;
    //margin: 30px;
    //padding: 30px;
    .Avatar {
      width: 100%;
      text-align: center;
      img {
        width: 120px;
        height: 120px;
        overflow: hidden;
      }
    }
    .headImgbtn {
      height: 80px;
      margin-top: 30px;
      button {
        width: 180px;
        height: 70px;
      }
    }
  }
  .blue-box {
  background-color: #007AFF;
  height: 35px;
  width: 6px;
  border-radius: 6px;
  float: left;
  margin-top: 8px;
  margin-right: 8px;
}
.updateBtn {
  //position: fixed;
  ////left: 30px;
  //bottom: 40px;
  width: 100%;
  height: 88px;
  display: flex;
  padding: 0 20px;
  margin-top: 78px;
  .van-button {
    background: #4581F8;
    border-radius: 8px;
  }
  .van-col:first-child .van-button{
    background-color: #fff !important;
  }
}
.redTip {
  color: red;
}
.blackTip {
  color: #333333;
}
</style>
